<template functional>
  <div class="product-price">
    <!-- Se non ci sono promozioni per il prodotto o se è esaurito -->
    <div class="price" v-if="!props.product.warehousePromo">
      <div class="price_block">
        <div v-if="props.product.priceDisplay" class="cur-price">
          {{ parent.$n(props.product.priceDisplay, "currency") }}
          <span
            class="weight-unit"
            v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
            >/{{ props.product.weightUnitDisplay }}
          </span>
        </div>
      </div>
    </div>
    <!-- promo buono sconto con prezzo pieno e label buono sconto !-->
    <div
      :class="props.product.warehousePromo.view.cssClass + ' price'"
      v-else-if="
        props.product.warehousePromo &&
          props.product.warehousePromo.view.cssClass.indexOf(
            'promo_buono B2E'
          ) > -1
      "
    >
      <div class="price_block">
        <div v-if="props.product.priceDisplay" class="cur-price">
          {{ parent.$n(props.product.priceDisplay, "currency") }}
          <span
            class="weight-unit"
            v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
            >/{{ props.product.weightUnitDisplay }}
          </span>
        </div>
      </div>
      <div
        class="product-badge"
        v-html="props.product.warehousePromo.view.header"
      ></div>
    </div>
    <!-- Se ci sono promozioni per il prodotto e se è disponibile -->
    <div v-else class="warehouse-promo-price">
      <div
        v-if="props.product.warehousePromo.view.permanent"
        v-html="props.product.warehousePromo.view.permanent"
        class="promo_permanent"
      ></div>
      <div
        v-if="props.product.warehousePromo.view.bubble"
        v-html="props.product.warehousePromo.view.bubble"
        class="promo_bubble"
      ></div>
      <div
        class="product_promo"
        :class="props.product.warehousePromo.view.cssClass"
      >
        <div class="promo_header">
          <span class="promo_content text-decoration-line-through">{{
            parent.$n(props.product.priceStandardDisplay, "currency")
          }}</span>
        </div>
        <div class="promo_body price-font">
          <span
            v-html="props.product.warehousePromo.view.body"
            class="promo_content"
          ></span>
        </div>
        <div
          v-html="props.product.warehousePromo.view.footer"
          class="promo_footer"
        ></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.price {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 1;
  color: $text-color;
  white-space: nowrap;
  height: 45px;
  justify-content: center;
  .weight-unit {
    font-size: 12px;
    margin-left: -5px;
  }
  .old-price {
    font-size: 12px;
    text-decoration: line-through;
  }
  .cur-price {
    font-weight: bold;
  }
  .other {
    white-space: nowrap;
    font-size: 12px;
    text-decoration: none !important;
  }
  .original {
    font-size: 16px;
  }
  .price-um {
    font-size: 10px;
    line-height: 15px;
    //padding: 2px 0 12px 0;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    max-height: 63px;
    align-items: center;
  }
}
.warehouse-promo-price {
  position: relative;
  justify-content: center;
  display: flex;
  .product_promo {
    width: 100%;
    max-width: 200px;
    display: flex;
    // gap: 4px;
    flex-wrap: nowrap;
    height: 100%;
    min-height: 35px;
    overflow: hidden;
    font-family: $heading-font-family;
    .promo_body,
    .promo_header {
      width: 70px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: $border-radius-root;
      white-space: nowrap;
      .promo_content {
        display: initial;

        .price_small {
          margin-right: 2px;
          margin-top: -2px;
          font-size: 13px;
        }
      }
    }
    .promo_header {
      font-weight: bold;
      text-transform: uppercase;
      background-color: $secondary;
      color: $white;
      font-weight: bold;
      line-height: 1;
      font-size: 12px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .price_old {
        // font-size: 12px;
        display: block;
        text-transform: lowercase;
        text-decoration: line-through;
        color: var(--v-primary-base);
        // font-family: "Maven Pro", sans-serif;
        .price_small.weight_unit {
          width: initial;
          display: block;
          margin-left: 3px;
        }
      }
    }
    .promo_body {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: #e20a15;
      color: #fff;
      font-weight: bold;
      font-size: 20px; //change fede palma
      line-height: 10px;
      .price_small.weight_unit {
        font-size: 11px;
      }
    }
    .price_old {
      text-decoration: line-through;
    }
    .promo_footer {
      display: none;
    }
  }

  .promo_bubble {
    position: absolute;
    top: -18px;
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: var(--v-primary-base);
    font-weight: bold;
    .price_old {
      text-decoration: line-through;
      text-align: center;
      color: $primary;
    }
  }

  .product_promo.promo_mxn {
    .promo_header {
      .promo_content {
        line-height: initial;
        font-size: 18px;
        .small {
          font-size: 14px;
        }
      }
    }
    .promo_body {
      .promo_content {
        margin-top: -2px;
        line-height: 16px;
      }
      .col_left {
        font-size: 12px;
      }
    }
    &.promo_2x1 {
      .promo_header {
        color: #008f47;
      }
      .promo_body {
        background-color: #008f47;
      }
    }
    &.promo_3x2 {
      .promo_header {
        color: #1a6ab2;
      }
      .promo_body {
        background-color: #1a6ab2;
      }
    }
    &.promo_3x1 {
      .promo_header {
        color: $black;
        background-color: $secondary;
      }
    }
  }

  .product_promo.promo_percent.NFO {
    .promo_header {
      font-size: 13px;
      text-align: center;
    }
  }

  .product_promo.promo_convenienza_sempre {
    .promo_header {
      .promo_content {
        margin-left: 11px;
        margin-bottom: 5px;
        line-height: 14px;
      }
      background-color: var(--v-promo-base);
      color: $white;
      z-index: 1;
      .text_small {
        font-size: 8px;
      }
      font-size: 17px;
    }
    .promo_body {
      .promo_content {
        display: flex;
        align-items: center;
        .col_right {
          margin-top: 2px;
          font-size: 18px;
        }
      }
      .white-border {
        width: 10px;
        height: 45px;
        path {
          stroke-width: 2;
          shape-rendering: geometricprecision;
        }
      }
    }
  }
  .product_promo.promo_percent {
    &.promo_sconto {
      .promo_header {
        font-size: 16px;
      }
    }
    .promo_header {
      text-align: center;
      font-size: 20px;
      padding: 0px 2px;
      padding-top: 2px;
      .col_left {
        margin-top: -3px;
      }
    }
    &.promo_tco {
      .promo_header {
        background-color: #cac593;
        .promo_content {
          margin-top: 3px;
          display: flex;
          flex-direction: column;
          .col_left {
            font-size: 12px;
          }
          .col-right {
            font-size: 16px;
          }
        }
      }
      .promo_body {
        background-color: #9c8d1a;
      }
    }
  }

  .promo_mxn.promo_discount {
    .promo_header {
      text-transform: none;
      .promo_content {
        line-height: 12px;
        margin-top: 4px;
        .percentage {
          font-size: 18px;
        }
        .pieces {
          font-size: 12px;
        }
      }
    }
    .promo_body {
      .promo_content {
        line-height: 16px;
        margin-top: -2px;
        .pieces {
          font-size: 12px;
        }
        .price_actual {
          font-size: 18px;
        }
      }
    }
  }

  .product_promo.special_price,
  .product_promo.special_price.promo_percent {
    .promo_header {
      text-align: center;
      font-size: 13px;
      padding: 0px 2px;
      padding-top: 2px;
    }
  }
  .product_promo.special_price_tigros_card,
  .product_promo.special_price_tigros_card.promo_percent {
    .promo_header {
      text-align: center;
      font-size: 12px;
      padding: 0px 2px;
      padding-top: 2px;
    }
  }
  .product_promo.promo_discount.promo_mxn.special_price
    .promo_header
    .promo_content {
    font-size: 13px;
  }
}
</style>
<script>
export default {
  name: "props.productPrice",
  props: {
    product: { type: Object, required: true }
  }
};
</script>
