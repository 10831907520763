<template>
  <div
    v-if="config.proposals && config.proposals.length > 0"
    :id="`banner-list-${config.categoryBlockId}`"
    class="banner-list"
    :style="style"
  >
    <div :class="{ container: container }">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_BannerList.TITLE"
        tag="h2"
      />
      <ebsn-meta
        :target="config"
        path="categoryblocktype_BannerList.DESCRIPTION"
        tag="div"
      />

      <component
        v-for="proposal in config.proposals"
        :key="proposal.id"
        :is="modelName"
        :proposal="proposal"
      />
    </div>
  </div>
</template>
<style lang="scss">
.banner-list-container {
  .swiper-slide {
    height: auto;
    .proposal-card.v-card {
      height: 100%;
    }
  }
}
</style>
<script>
import ProposalImage from "./elements/ProposalImage.vue";
import ProposalCardHorizontal from "./elements/ProposalCardHorizontal.vue";
import ProposalCardResponsive from "./elements/ProposalCardResponsive.vue";
import ProposalCard from "./elements/ProposalCard.vue";
import ProposalButton from "./elements/ProposalButton.vue";
import ProductCard from "./elements/ProductCard.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

export default {
  name: "BannerList",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ProposalImage,
    ProposalCardHorizontal,
    ProposalCardResponsive,
    ProposalCard,
    ProposalButton,
    ProductCard
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      swiperRef: null,
      swiperDefaultOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `#banner-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#banner-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#banner-slider-next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef && this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
    }
  }
};
</script>
