import BannerList from "./BannerList.vue";
import BannerSlider from "./BannerSlider.vue";
import CrmProductSlider from "./CrmProductSlider.vue";
import ProductSlider from "./ProductSlider.vue";
import ProductTab from "./ProductTab.vue";
import StaticImage from "./StaticImage.vue";
import StaticText from "./StaticText.vue";
import Tutorial from "./Tutorial.vue";

export default {
  install: app => {
    app.component("category-block", {
      functional: true,
      name: "category-block",
      props: {
        target: {
          type: Object,
          required: false
        },
        position: {
          type: String,
          required: false
        },
        config: {
          type: Object,
          required: false
        },
        container: {
          type: Boolean,
          default: true
        },
        productId: {
          type: Number,
          required: false
        },
        categoryId: {
          type: Number,
          required: false
        }
      },
      render: function(createElement, context) {
        let cfg;
        if (context.props.config) {
          cfg = context.props.config;
        } else if (context.props.position) {
          cfg = context.props.target?.categoryBlocks?.find(
            categoryBlock =>
              categoryBlock.templateBlock.codInt == context.props.position
          );
        } else {
          cfg = context.props.target?.categoryBlocks?.[0];
        }
        if (cfg) {
          // const cmp = defineAsyncComponent({
          //   loader: () => import("@/components/categoryBlock/BannerSlider.vue")
          // });
          let cmp;

          //   = () =>
          //     import(
          //       `@/components/categoryBlock/${cfg.templateBlockType.modeName}.vue`
          //     );
          switch (cfg.templateBlockType.modeName) {
            case "BannerList":
              cmp = BannerList;
              break;
            case "BannerSlider":
              cmp = BannerSlider;
              break;
            case "CrmProductSlider":
              cmp = CrmProductSlider;
              break;
            case "ProductSlider":
              cmp = ProductSlider;
              break;
            case "ProductTab":
              cmp = ProductTab;
              break;
            case "StaticImage":
              cmp = StaticImage;
              break;
            case "StaticText":
              cmp = StaticText;
              break;
            case "Tutorial":
              cmp = Tutorial;
              break;
          }

          if (cmp) {
            const id = `category-block-${cfg.categoryBlockId}`;
            return createElement(cmp, {
              props: {
                id: id,
                config: cfg,
                position: context.props.position,
                container: context.props.container,
                productId: context.props.productId,
                categoryId: context.props.categoryId
              },
              staticClass: context.data.staticClass + " " + context.data.class,
              attrs: {
                style: context.data.attrs.style,
                class: context.data.attrs.class
              }
            });
          }
        }
        return null;
      }
    });
  }
};
