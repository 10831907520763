import SelfscanningService from "~/service/selfscanningService";

const UPDATE_PRICE_DISPLAY_DISCLAIMER = "updatePriceDisplayDisclaimer";
const ADD_TO_LEAFLET_LIST = "addToLeafletList";
const REMOVE_FROM_LEAFLET_LIST = "removeFromLeafletList";
const EMPTY_LEAFLET_LIST = "emptyLeafletList";
const SET_CART_SSC = "SET_CART_SSC";
const EMPTY_CART_SSC = "emptyCartSSC";

const state = {
  priceDisplayDisclaimer: true,
  leafletList: [],
  selfCart: {}
};
const getters = {
  getProductsByLeafletId: state => leafletId => {
    const index = state.leafletList.findIndex(
      el => el.leaflet.leafletId == leafletId
    );
    if (index != -1) {
      return state.leafletList[0].products;
    } else return [];
  },
  getCartSSC: state => {
    return state.selfCart;
  },
  getLastItemSSC: state => {
    return state.selfCart.lastCartItem?.itemId;
  }
};

const actions = {
  [UPDATE_PRICE_DISPLAY_DISCLAIMER]({ commit }, value) {
    commit(UPDATE_PRICE_DISPLAY_DISCLAIMER, value);
  },
  [ADD_TO_LEAFLET_LIST]({ commit }, value) {
    commit(ADD_TO_LEAFLET_LIST, value);
  },
  [REMOVE_FROM_LEAFLET_LIST]({ commit }, value) {
    commit(REMOVE_FROM_LEAFLET_LIST, value);
  },
  [EMPTY_LEAFLET_LIST]({ commit }, value) {
    commit(EMPTY_LEAFLET_LIST, value);
  },
  async loadCartSSC({ commit }) {
    try {
      const result = await SelfscanningService.view();

      commit(SET_CART_SSC, result.data.data);
      return result.data.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async updateCartSSC({ commit }, value) {
    commit(SET_CART_SSC, value);
  },
  async emptyCartSSC({ commit }) {
    commit(EMPTY_CART_SSC);
  }
};

const mutations = {
  [UPDATE_PRICE_DISPLAY_DISCLAIMER]: (state, value) => {
    state.priceDisplayDisclaimer = value;
  },
  [ADD_TO_LEAFLET_LIST]: (state, { product, leaflet }) => {
    const index = state.leafletList.findIndex(
      el => el.leaflet.leafletId === leaflet.leafletId
    );
    if (index != -1) {
      const found = state.leafletList[index].products.find(
        el => el.productId === product.productId
      );
      if (!found) {
        state.leafletList[index].products.push(product);
        global.EventBus.$emit("success", {
          message: global.vm.$t("leaflet.list.productAddText")
        });
      }
    } else {
      state.leafletList.push({ leaflet: leaflet, products: [product] });
      global.EventBus.$emit("success", {
        message: global.vm.$t("leaflet.list.productAddText")
      });
    }
  },
  [REMOVE_FROM_LEAFLET_LIST]: (state, { productId, leafletId }) => {
    const index = state.leafletList.findIndex(
      el => el.leaflet.leafletId == leafletId
    );
    if (index != -1) {
      for (let i = 0; i < state.leafletList[0].products.length; i++) {
        if (state.leafletList[0].products[i].productId == productId) {
          state.leafletList[0].products.splice(i, 1);
          break;
        }
      }
    }
  },
  [EMPTY_LEAFLET_LIST]: (state, value) => {
    const index = state.leafletList.findIndex(
      el => el.leaflet.leafletId == value
    );
    if (index != -1) {
      state.leafletList.splice(index, 1);
    }
  },
  [SET_CART_SSC](state, value) {
    state.selfCart = value;
  },
  [EMPTY_CART_SSC](state) {
    state.selfCart = {};
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
