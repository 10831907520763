import analyticsService from "~/service/analyticsService";
import CatalogService from "@/commons/service/catalogService";

import axios from "~/plugins/axios";

export default {
  provinceList(countryId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/province-list", {
        params: {
          country_id: countryId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  searchCitiesFromCap(cap) {
    // fidelityapi API cannot be invoked in development environment (i.e. localhost),
    // so we use complete URL
    let url = `https://www.tigros.it/fidelityapi/api/city/search/${cap}`;
    return axios
      .get(url)
      .then(data => {
        return data.data;
      })
      .catch(error => error);
  },
  getCountries(continentId, homeDeliveryOnly) {
    return axios
      .get("/ebsn/api/geo/country-list", {
        params: {
          continent_id: continentId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  selectAddressClass(addressId, addressClassId) {
    return axios
      .get("/ebsn/api/tigros-address-class-controller/set-address-class-id", {
        params: {
          address_id: addressId,
          address_class_id: addressClassId
        }
      })
      .then(data => {
        return data.data;
      });
  },
  getList(storeId, calculateStock) {
    const params = {
      store_id: storeId,
      calculate_stock: calculateStock,
      show_sectors: false
    };
    return axios
      .get("/ebsn/api/smart-cart/view", {
        params: params
      })
      .then(data => data.data.data)
      .catch(error => error);
  },
  addProductsToList(
    items,
    storeId = 7,
    showSectors = global.config.showSectors
  ) {
    let params = {
      items: []
    };
    try {
      for (var i = 0; i < items.length; i++) {
        let product = items[i].product;

        params.items.push({
          productId: product.productId,
          quantity:
            product.productInfos.TIPOLOGIA === "Sfuso"
              ? items[i].weight
              : items[i].quantity
        });
      }
    } catch (err) {
      console.log(err);
    }

    let url =
      `/ebsn/api/smart-cart/add_prod?store_id=` +
      storeId +
      `&show_sectors=` +
      showSectors;

    return axios.post(url, params).then(data => {
      if (data && data.data) {
        analyticsService.addProductToList(items);

        global.EventBus.$emit("success", {
          message: global.vm.$tc(
            "list.productAddToList",
            params.items ? params.items.length : 0
          )
        });
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  updateProductsInList(storeId, item, newQuantity) {
    const decodedQuantity = CatalogService.encodeProductQuantity(
      item.product,
      newQuantity
    );
    let url = `/ebsn/api/smart-cart/update_prods?store_id=` + storeId;
    const params = {
      items: [{ itemId: item.itemId, quantity: decodedQuantity }]
    };

    const queryParams = {
      show_sectors: false
    };

    return axios.post(url, params, { params: queryParams }).then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  removeItemFromList(storeId, itemId, productId) {
    let params = {
      store_id: 7,
      items: [{ itemId: itemId, quantity: 0, productId: productId }],
      show_sectors: false
    };
    let url = `/ebsn/api/smart-cart/delete_prods?store_id=` + storeId;
    params.hash = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    return axios
      .post(url, params, {
        params: { show_sectors: false }
      })
      .then(data => {
        if (data && data.data) {
          //analyticsService.removeProductToList();

          return data.data.data;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  async setCartInfo(name, value, doc) {
    let params = {
      property: name,
      value: value,
      doc: doc
    };
    return axios
      .post("/ebsn/api/smart-cart/update-cart-info", params, {
        show_sectors: global.config.showSectors,
        store_id: 7
      })
      .then(data => {
        return data.data.data;
      });
  },
  getTimelost() {
    return axios
      .get("/ebsn/api/self-scanning-suborder-delivery/list-timeslot")
      .then(data => {
        return data.data.data.results;
      });
  },
  timeslotSelect() {
    return axios
      .get("/ebsn/api/self-scanning-suborder-delivery/select-timeslot")
      .then(data => {
        return data.data.data.results;
      });
  }
};
