<template>
  <v-card outlined rounded="md" class="product-card my-4">
    <div class="product">
      <v-row class="w-100 d-flex flex-row body py-4">
        <v-col cols="4">
          <img
            :src="proposal.metaData.category_proposal_type.IMAGE_DESKTOP"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            style="max-width: 100%"
            :alt="'Immagine' + proposal.name"
            :title="proposal.name"
          />
        </v-col>
        <v-col cols="8">
          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
            style="height: 100%"
          >
            <span class="name font-weight-bold">
              {{ proposal.name }}
            </span>
            <span class="short_descr" style="">
              {{ proposal.descr }}
            </span>
            <span class="descr">
              {{ proposal.description }}
            </span>
            <v-spacer />
            <v-card-actions>
              <v-btn
                :href="proposal.link"
                @click.prevent="handleLink"
                color="secondary"
                flex
                depressed
              >
                {{
                  $ebsn.meta(
                    proposal,
                    "category_proposal_type.BUTTON_TEXT",
                    "More Info"
                  )
                }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card {
  .v-rating .v-icon {
    font-size: 16px;
  }
  .product-img {
    width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 17px;
      width: 100px;
      height: 100px;
    }
  }
  .product .description .name {
    font-family: $heading-font-family;
  }
  .promo-wrapper {
    position: absolute;
    top: -1px;
    left: -1px;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }
  .cart-item-info {
    margin-bottom: -21px;
  }

  .top {
    height: 26px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-price {
      width: 140px;
      text-align: center;
    }
  }

  .actions {
    .selectOptions {
      margin-right: 3px;
    }
  }

  .selectOptionsMenu {
    z-index: 12;
  }
}
</style>
<script>
import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

export default {
  name: "ProductCard",
  props: {
    proposal: { type: Object, required: true },
    position: { type: Number, default: undefined }
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.value;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    }
  },
  mounted() {}
};
</script>
