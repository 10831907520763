<template>
  <div
    class="qty-wrap rounded-sm"
    :class="{ 'not-empty': quantity > 0, 'no-border': quantity < 1 }"
    @mousedown.stop
  >
    <v-btn
      v-if="enableAcqBox && product.logisticPackagingItems && quantity <= 0"
      :aria-label="
        `Aggiungi un cartone da ${product.logisticPackagingItems} al carrello`
      "
      color="primary"
      outlined
      height="45"
      class="btn-add-box mr-1"
      small
      @click.stop.prevent="
        addToCart(product.logisticPackagingItems, undefined, {
          searchUid: product.searchUid,
          position: position
        })
      "
    >
      <div>
        {{ product.logisticPackagingItems }}
        <v-icon color="primary" small>$pack</v-icon>
      </div>
      <div class="text-uppercase small">
        pezzi
      </div>
    </v-btn>

    <v-btn
      v-if="!modify"
      tile
      aria-label="Diminuisci quantità"
      depressed
      color="grey lighten-2"
      small
      class="minus"
      @click.stop.prevent="
        minus(
          true,
          { infos: { ...selectedOptions } },
          {
            searchUid: product.searchUid,
            position: position
          }
        )
      "
      @mousedown.stop
    >
      <v-icon>$minus</v-icon>
    </v-btn>
    <v-btn
      v-if="modify"
      tile
      aria-label="Diminuisci quantità"
      depressed
      color="grey lighten-2"
      small
      class="minus"
      @click.stop.prevent="
        handleMinus(
          true,
          { infos: { ...selectedOptions } },
          {
            searchUid: product.searchUid,
            position: position
          }
        )
      "
      @mousedown.stop
    >
      <v-icon>$minus</v-icon>
    </v-btn>
    <div class="val-cnt">
      <span class="val">{{ quantity }}{{ unit }}</span>
      <span class="small">{{ quantityPerUnit }}</span>
    </div>
    <v-btn
      v-if="!modify"
      tile
      aria-label="Aumenta quantità"
      class="plus"
      small
      :class="quantity > 0 ? '' : 'rounded-sm'"
      depressed
      :color="quantity > 0 ? 'grey lighten-2' : 'primary'"
      @click.stop.prevent="
        plus(
          true,
          {
            infos: {
              ...selectedOptions
            }
          },
          {
            searchUid: product.searchUid,
            position: position
          }
        )
      "
    >
      <!-- v-ripple -->
      <v-icon v-if="quantity > 0">$plus</v-icon>
      <template v-else>
        <v-icon>$cart</v-icon>
        <span v-if="label" class="font-weight-bold white--text text-uppercase">
          {{ label }}
        </span>
      </template>
    </v-btn>
    <v-btn
      v-if="modify"
      tile
      aria-label="Aumenta quantità"
      class="plus"
      small
      :class="quantity > 0 ? '' : 'rounded-sm'"
      depressed
      :color="quantity > 0 ? 'grey lighten-2' : 'primary'"
      @click.stop.prevent="
        handlePlus(
          true,
          {
            infos: {
              ...selectedOptions
            }
          },
          {
            searchUid: product.searchUid,
            position: position
          }
        )
      "
    >
      <!-- v-ripple -->
      <v-icon v-if="quantity > 0">$plus</v-icon>
      <template v-else>
        <v-icon>$cart</v-icon>
        <span v-if="label" class="font-weight-bold white--text text-uppercase">
          {{ label }}
        </span>
      </template>
    </v-btn>
  </div>
</template>
<script>
import productMixin from "~/mixins/product";

export default {
  name: "ProductQty",
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    updateCart: { type: Boolean, default: global.config.updateCart },
    label: { type: String },
    selectedOptions: { type: Object },
    position: { type: Number, default: undefined },
    modify: {
      type: Boolean,
      default: false
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  mixins: [productMixin],
  computed: {
    enableAcqBox() {
      return (
        this.product.productInfos?.ENABLE_ACQ_BOX &&
        this.product.productInfos?.ENABLE_ACQ_BOX !== "no"
      );
    },
    packageCount() {
      if (this.product.productInfos.TIPOLOGIA != "Sfuso" && this.item) {
        if (
          this.product.logisticPalletItems > 0 &&
          this.item.quantity % this.product.logisticPalletItems == 0
        ) {
          return (
            this.item.quantity / this.product.logisticPalletItems + " pallet"
          );
        } else if (
          this.product.logisticPackagingItems > 0 &&
          this.item.quantity % this.product.logisticPackagingItems == 0
        ) {
          let unit = this.item.quantity / this.product.logisticPackagingItems;
          return "(" + unit + (unit == 1 ? " collo" : " colli") + ")";
        }
      }
      return "(0 colli)";
    }
  },
  methods: {
    handlePlus(se, infos, param) {
      this.plus(se, infos, param);
      // this.item.quantity++;
      this.$emit("updatePlus");
    },
    handleMinus(se, infos, param) {
      this.plus(se, infos, param);
      // this.item.quantity--;
      this.$emit("updateMinus");
    }
  },
  mounted() {
    var _this = this;
    console.log("PRODUCT !!!: ", _this.product);
  }
};
</script>
