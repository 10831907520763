var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"address-selector-container fill-height secondary--text px-3"},[_c('v-layout',{attrs:{"fill-height":"","column":"","mt-5":""}},[_c('ebsn-meta',{staticClass:"text-center",attrs:{"target":_vm.category,"path":"category_info.TITLE","tag":"h1"}}),_c('ebsn-meta',{staticClass:"text-center mt-2",attrs:{"target":_vm.category,"path":"category_info.DESCRIPTION"}}),_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"position1","container":false}}),_c('v-row',{staticClass:"my-3 tabs",attrs:{"no-gutters":"","justify":"space-between","align":"center"}},_vm._l((_vm.deliveryServices),function(service,idx){return _c('v-col',{key:service.deliveryServiceId,staticClass:"px-2",attrs:{"cols":_vm.deliveryServiceButtonsLength},on:{"click":function($event){return _vm.fetchAddresses(service, idx)}}},[_c('div',{staticClass:"tab d-flex flex-column align-center justify-center flex-grow-1 py-3 py-sm-1 rounded-sm",class:service.deliveryServiceId === _vm.selectedDeliveryServiceId
              ? `${service.cssClass} selected`
              : `${service.cssClass}`},[_c('v-img',{staticClass:"mr-1",attrs:{"src":"/img_layout/services_icons/delivery-small.jpg","alt":'Immagine' + service.name,"contain":"","height":_vm.$vuetify.breakpoint.xs ? 60 : 'auto',"width":_vm.$vuetify.breakpoint.xs ? 70 : 200}})],1)])}),1),_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-list',{staticClass:"address-list",attrs:{"dense":""}},[(
            _vm.deliveryService.deliveryServiceId == 2 &&
              _vm.eldersAddressEnabled &&
              !_vm.eldersInfoRead &&
              _vm.eldersAddress == null &&
              !_vm.loading
          )?_c('v-list-item',[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t("addressSelector.elder.explenation"))+" "),_c('v-list-item-action',{staticClass:"d-block"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.eldersInfoRead = true}}},[_vm._v(" "+_vm._s(_vm.$t("addressSelector.elder.explenationAccept"))+" ")])],1)],1)],1):(!_vm.loading)?_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.shippingAddress),callback:function ($$v) {_vm.shippingAddress=$$v},expression:"shippingAddress"}},_vm._l((_vm.filteredAddresses),function(address){return _c('v-list-item',{key:address.addressId,staticClass:"px-0 px-sm-auto",on:{"click":function($event){return _vm.setAddress(address)}}},[_c('v-icon',{staticClass:"mr-2 ml-2 ml-sm-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(address.selected ? "$radioOn" : "$radioOff")+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text font-weight-bold text-caption text-sm-body-2 mb-1"},[_vm._v(" "+_vm._s(address.addressName)+" ")]),_c('v-list-item-subtitle',{staticClass:"primary--text text-caption text-sm-body-2"},[_vm._v(" "+_vm._s(_vm.$t("navbar.address.2.list", address))+" ")])],1),_c('v-list-item-action',{staticClass:"flex-row align-center ml-0"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(address.addressType == 'work')?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("$work")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Indirizzo di consegna al lavoro")])]),(
                  _vm.deliveryService.deliveryServiceId == 2 &&
                    _vm.eldersAddressEnabled &&
                    address.addressType != 'work'
                )?[(_vm.eldersAddress == null)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setAsEldersAddress(address)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t("addressSelector.elder.selectAddressBtn")))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("addressSelector.elder.selectAddressTooltip"))+" ")])]):(_vm.eldersAddress.addressId == address.addressId)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1"},'v-icon',attrs,false),on),[_vm._v("$elder")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("addressSelector.elder.elderIconTooltip"))+" ")])]):_vm._e()]:_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                      address.addressId == _vm.cart.user.defaultStoreAddressId ||
                        address.addressId ==
                          _vm.cart.user.defaultDeliveryAddressId
                    )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("$favourite")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(address.addressType == "home" ? _vm.$t("addressSelector.usualAddress") : _vm.$t("addressSelector.usualPdv")))])]),(address.addressType == 'home')?_c('v-btn',{staticClass:"no-border",attrs:{"icon":"","small":_vm.$vuetify.breakpoint.xs},on:{"click":function($event){$event.stopPropagation();return _vm.edit(address)}}},[_c('v-icon',[_vm._v("$edit")])],1):_vm._e(),(address.addressType == 'home')?_c('v-btn',{staticClass:"no-border",attrs:{"small":_vm.$vuetify.breakpoint.xs,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove(address)}}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e()],2)],1)}),1):_vm._e()],1),_c('v-card-actions',{staticClass:"justify-end"},[(_vm.deliveryService.deliveryServiceId === 2)?_c('v-btn',{attrs:{"color":"secondary","large":"","depressed":"","to":{ name: 'EditAddresses' }},on:{"click":function($event){return _vm.$emit('submit', false)}}},[_vm._v(" AGGIUNGI INDIRIZZO ")]):_vm._e()],1)],1),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2","container":false}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }