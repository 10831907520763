<template>
  <v-container
    class="address-selector-container fill-height secondary--text px-3"
  >
    <v-layout fill-height column mt-5>
      <ebsn-meta
        :target="category"
        path="category_info.TITLE"
        class="text-center"
        tag="h1"
      ></ebsn-meta>
      <ebsn-meta
        :target="category"
        path="category_info.DESCRIPTION"
        class="text-center mt-2"
      ></ebsn-meta>
      <category-block
        :target="category"
        position="position1"
        class="category-block category-block-1"
        :container="false"
      />
      <v-row
        no-gutters
        justify="space-between"
        align="center"
        class="my-3 tabs"
      >
        <v-col
          :cols="deliveryServiceButtonsLength"
          class="px-2"
          v-for="(service, idx) in deliveryServices"
          :key="service.deliveryServiceId"
          @click="fetchAddresses(service, idx)"
        >
          <div
            class="tab d-flex flex-column align-center justify-center flex-grow-1 py-3 py-sm-1 rounded-sm"
            :class="
              service.deliveryServiceId === selectedDeliveryServiceId
                ? `${service.cssClass} selected`
                : `${service.cssClass}`
            "
          >
            <v-img
              src="/img_layout/services_icons/delivery-small.jpg"
              :alt="'Immagine' + service.name"
              contain
              class="mr-1"
              :height="$vuetify.breakpoint.xs ? 60 : 'auto'"
              :width="$vuetify.breakpoint.xs ? 70 : 200"
            >
            </v-img>
          </div>
        </v-col>
      </v-row>
      <v-card :loading="loading" flat>
        <!-- <v-text-field
          class="rounded-sm"
          hide-details
          v-model="searchText"
          clearable
          filled
          solo
          dense
          :label="
            $t('navbar.service.' + selectedDeliveryServiceId + '.searchLabel')
          "
        >
          <v-icon slot="append" color="red">
            $search
          </v-icon>
        </v-text-field> -->
        <v-list dense class="address-list">
          <v-list-item
            v-if="
              deliveryService.deliveryServiceId == 2 &&
                eldersAddressEnabled &&
                !eldersInfoRead &&
                eldersAddress == null &&
                !loading
            "
          >
            <v-alert type="success">
              {{ $t("addressSelector.elder.explenation") }}
              <v-list-item-action class="d-block">
                <v-btn @click="eldersInfoRead = true" color="primary" depressed>
                  {{ $t("addressSelector.elder.explenationAccept") }}
                </v-btn>
              </v-list-item-action>
            </v-alert>
          </v-list-item>
          <v-list-item-group
            v-else-if="!loading"
            v-model="shippingAddress"
            color="primary"
          >
            <v-list-item
              v-for="address in filteredAddresses"
              :key="address.addressId"
              @click="setAddress(address)"
              class="px-0 px-sm-auto"
            >
              <v-icon class="mr-2 ml-2 ml-sm-2" color="primary">
                {{ address.selected ? "$radioOn" : "$radioOff" }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text font-weight-bold text-caption text-sm-body-2 mb-1"
                >
                  {{ address.addressName }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="primary--text text-caption text-sm-body-2"
                >
                  {{ $t("navbar.address.2.list", address) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="flex-row align-center ml-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="address.addressType == 'work'"
                      >$work</v-icon
                    >
                  </template>
                  <span>Indirizzo di consegna al lavoro</span>
                </v-tooltip>

                <template
                  v-if="
                    deliveryService.deliveryServiceId == 2 &&
                      eldersAddressEnabled &&
                      address.addressType != 'work'
                  "
                >
                  <v-tooltip left v-if="eldersAddress == null">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        depressed
                        small
                        @click.stop.prevent="setAsEldersAddress(address)"
                        >{{
                          $t("addressSelector.elder.selectAddressBtn")
                        }}</v-btn
                      >
                    </template>
                    <span>
                      {{ $t("addressSelector.elder.selectAddressTooltip") }}
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    left
                    v-else-if="eldersAddress.addressId == address.addressId"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-1" v-bind="attrs" v-on="on"
                        >$elder</v-icon
                      >
                    </template>
                    <span>
                      {{ $t("addressSelector.elder.elderIconTooltip") }}
                    </span>
                  </v-tooltip>
                </template>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="
                        address.addressId == cart.user.defaultStoreAddressId ||
                          address.addressId ==
                            cart.user.defaultDeliveryAddressId
                      "
                      >$favourite</v-icon
                    >
                  </template>
                  <span>{{
                    address.addressType == "home"
                      ? $t("addressSelector.usualAddress")
                      : $t("addressSelector.usualPdv")
                  }}</span>
                </v-tooltip>
                <!-- <template
                  v-if="
                    address.addressClass &&
                      address.addressClass.length > 0 &&
                      filteredAddressClasses(address.addressClass).length > 0
                  "
                >
                  <AddressClass
                    v-for="addressClass in filteredAddressClasses(
                      address.addressClass
                    )"
                    :key="addressClass['address-class-id']"
                    :addressClass="addressClass"
                  />
                </template> -->
                <v-btn
                  icon
                  class="no-border"
                  :small="$vuetify.breakpoint.xs"
                  @click.stop="edit(address)"
                  v-if="address.addressType == 'home'"
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
                <v-btn
                  :small="$vuetify.breakpoint.xs"
                  icon
                  class="no-border"
                  @click.stop="remove(address)"
                  v-if="address.addressType == 'home'"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions class="justify-end">
          <!-- <v-btn
            v-if="
              deliveryService.deliveryServiceId === 2 &&
                filteredAddresses.length == 0
            "
            large
            depressed
            color="primary"
            :to="{ name: 'Page', params: { pageName: 'i-servizi-tigros' } }"
            @click="$emit('submit', false)"
            align="center"
          >
            i nostri servizi
          </v-btn> -->

          <v-btn
            v-if="deliveryService.deliveryServiceId === 2"
            color="secondary"
            large
            depressed
            :to="{ name: 'EditAddresses' }"
            @click="$emit('submit', false)"
          >
            AGGIUNGI INDIRIZZO
          </v-btn>
        </v-card-actions>
      </v-card>
      <category-block
        :target="category"
        position="position2"
        class="category-block category-block-2"
        :container="false"
      />
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.address-selector-container {
  h3 p,
  .desc p {
    margin-bottom: 0 !important;
  }
  .tabs {
    .tab {
      border: 1px solid $gray-border-color;
      &.selected {
        color: $white !important;
        border-color: $primary;
        .v-icon,
        span {
          color: $white !important;
        }
      }
    }
    .v-icon {
      font-size: 60px;
      height: 80px;
    }
  }
  .address-list {
    overflow-y: auto;
    min-height: 200px;
    max-height: calc(100vh - 400px);

    .v-list-item {
      color: $text-color;
      border-bottom: 1px solid var(--v-grey-lighten2);
      min-height: 50px;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-height: calc(100vh - 225px);
      .v-list-item__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .v-list-item__title {
          align-self: flex-start;
        }
        .v-list-item__subtitle {
          white-space: normal;
        }
      }
    }
    .v-list-item__title,
    .v-list-item__subtitle {
      line-height: 18px !important;
    }
    .v-list-item--active {
      background: var(--v-grey-lighten4);
      color: $text-color;
      border-radius: $border-radius-root;
      .v-list-item__subtitle {
        color: $text-color;
      }
      .icon-edit {
        color: $text-color;
      }
    }
  }
  .v-text-field .v-input__slot {
    padding: 0 24px !important;
  }
}
</style>
<script>
// @ is an alias to /src
import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
import TigrosCustomService from "@/service/tigrosCustomService";
// import AddressService from "~/service/addressService";
// import CategoryBlock from "../categoryBlock/CategoryBlock.vue";

import { mapState, mapGetters } from "vuex";
// import AddressClass from "../AddressClass.vue";

import get from "lodash/get";

export default {
  name: "AddressSelector",
  data() {
    return {
      store: {},
      addresses: [],
      loading: null,
      shippingAddress: null,
      deliveryService: null,
      tab: 1,
      searchText: null,
      category: {},
      eldersInfoRead: false
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    eldersAddressEnabled() {
      let eldersLegalFound = false;
      for (let i = 0; i < this.user.legals.length; i++) {
        if ([2, 3].includes(this.user.legals[i].legalId)) {
          eldersLegalFound = true;
          break;
        }
      }
      return eldersLegalFound;
    },
    eldersAddress() {
      let found = this.filteredAddresses
        .map(function(address) {
          // Does current address has any type '1' class?
          let addressClasses = address.addressClass ? address.addressClass : [];
          let elderEnabled = addressClasses.reduce(function(
            total,
            currentValue
          ) {
            return total || currentValue["address-class-id"] === 1;
          },
          false);
          return {
            addressId: address.addressId,
            addressName: address.addressName,
            elderEnabled: elderEnabled
          };
        })
        .filter(function(address) {
          return address.elderEnabled;
        })
        .shift();

      return found ? found : null;
    },
    deliveryServiceButtonsLength() {
      return 12 / this.deliveryServices.length;
    },
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    selectedDeliveryServiceId() {
      return 2;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.deliveryServiceId === 2;
        });
      }
      return [];
    }
  },
  methods: {
    addressClass(address) {
      if (address && address.addressClass && address.addressClass.length) {
        return address.addressClass[0];
      }
    },
    // filteredAddressClasses(addressClasses) {
    //   // show only addressClass "new" that is addressClassType: 2
    //   return addressClasses.filter(
    //     addressClass =>
    //       addressClass.addressClassGroup.addressClassGroupId == 2 &&
    //       addressClass.iconSource &&
    //       addressClass.iconSource != ""
    //   );
    // },
    async setAsEldersAddress(address) {
      // Try to select address class
      try {
        let res = await TigrosCustomService.selectAddressClass(
          address.addressId,
          1
        );
        if (res.response.status == 0) {
          this.loading = true;
          await this.$store.dispatch("cart/setAddress", {
            addressType: address.addressType,
            addressId: address.addressId
          });
        }
        this.fetchAddresses(this.selectedDeliveryServiceId);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
      // this.eldersAddress = deliveryController.warehouses[index];

      // .then(function() {
      // });
    },
    async remove(address) {
      let _this = this;
      const res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        _this.addresses = response.addresses.addresses;
      }
    },
    edit(address) {
      this.$router.push({
        name: "EditAddresses",
        params: { addressId: address.addressId }
      });
      this.$emit("submit", false);
    },
    async fetchStore() {
      let _this = this;
      let store = await StoreService.getStoreById(_this.cart.store.storeId);
      _this.store = store;

      _this.store.deliveryServices.find(deliveryService => {
        if (deliveryService.deliveryServiceId == 2) {
          _this.deliveryService = deliveryService;
        }
      });
    },
    async fetchAddresses(deliveryService, idx) {
      let _this = this;
      if (typeof idx !== "undefined") {
        _this.tab = idx;
      }
      _this.loading = true;
      _this.searchText = null;
      // let deliveryServiceId =
      //   deliveryService.deliveryServiceId || deliveryService;
      _this.deliveryService = deliveryService;
      _this.addresses = await DeliveryService.getWarehousesList("home");

      _this.addresses = _this.addresses.addresses;

      _this.addresses.find((address, index) => {
        if (address.addressId == _this.cart.shippingAddress.addressId) {
          _this.shippingAddress = index;
        }
      });
      _this.loading = false;
    },
    async setAddress(address) {
      let _this = this;
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });

      this.loading = false;
      if (data) {
        _this.$emit("submit", true);
        //Do not go home on address change
        // setTimeout(function() {
        //   _this.$router.push({ name: "Home" });
        // }, 200);
      }
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "service-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.fetchStore();
    this.fetchAddresses(2);
    this.fetchCategory();
  }
};
</script>
