import axios from "~/plugins/axios";
import { defaultParams } from "./defaultParams";

export default {
  getCategoryTree(
    filtered = global.config.filterEmptyCategory,
    depth = global.config.categoryTreeDepth
  ) {
    return axios
      .get("/ebsn/api/category", {
        params: {
          filtered: filtered,
          depth: depth,
          ...defaultParams()
        }
      })
      .then(data => {
        return data.data.data.categories.filter(function(cat) {
          return cat.categoryId == 148000612 || cat.categoryId == 148000614;
        });
        // return data.data.data.categories;
      });
  },
  getCategoryBySlug(
    slug,
    filtered = global.config.filterEmptyCategory,
    query,
    skipCache,
    previewParams
  ) {
    // eslint-disable-next-line no-debugger
    // debugger;
    // let hash = Math.random()
    //   .toString(36)
    //   .replace(/[^a-z]+/g, "")
    //   .substr(0, 5);
    let params = {
      slug: slug,
      filtered: filtered,
      ...defaultParams(),
      skip_cache: skipCache,
      ...previewParams
    };
    if (query) {
      params.q = query;
    }
    return axios
      .get("/ebsn/api/category", {
        params: params
      })
      .then(data => {
        return data.data.data.category;
      });
  },
  getCategoryById(categoryId, filtered = global.config.filterEmptyCategory) {
    return axios
      .get("/ebsn/api/category", {
        params: {
          categoryId: categoryId,
          filtered: filtered,
          ...defaultParams()
        }
      })
      .then(data => {
        return data.data.data.category;
      });
  },
  getCategoryBlock(categoryBlockId) {
    return axios
      .get("/ebsn/api/category-block/info", {
        params: {
          categoryBlockId: categoryBlockId
        }
      })
      .then(data => {
        return data.data.data;
      });
  }
};
